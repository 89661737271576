<template>
    <div class="">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="mx-auto max-w-2xl text-center">
                <h2
                    class="text-3xl font-bold tracking-tight sm:text-4xl"
                >
                    All-in-one domain platform
                </h2>
                <p class="mt-2 text-lg leading-8">
                    Simplify your business. Organize your clients.
                </p>
                <p class="mt-2 text-lg leading-8">
                    Manage your domain portfolio
                </p>
            </div>
            <div
                class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3"
            >
                <article
                    v-for="post in posts"
                    :key="post.id"
                    class="flex flex-col items-start justify-between"
                >
                    <div class="relative w-full">
                        <NuxtImg
                            :src="post.imageUrl"
                            :alt="post.imageAlt"
                            :title="post.imageTitle"
                            preset="blogThumbs"
                            loading="lazy"
                            class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                        />
                        <div
                            class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"
                        />
                    </div>
                    <div class="max-w-xl">
                        <div class="mt-8 flex items-center gap-x-4 text-xs">
                            <!-- <time
                                :datetime="post.datetime"
                                class="text-gray-500"
                                >{{ post.date }}</time
                            > -->
                            <!-- <UBadge>
                                <a :href="post.category.href">{{
                                    post.category.title
                                }}</a>
                            </UBadge> -->
                        </div>
                        <div class="group relative">
                            <h3
                                class="mt-3 text-lg font-semibold leading-6 group-hover:underline"
                            >
                                <!-- <a :href="post.href"> -->
                                    <span class="absolute inset-0" />
                                    {{ post.title }}
                                <!-- </a> -->
                            </h3>
                            <p
                                class="mt-5 line-clamp-4 text-sm leading-6"
                            >
                                {{ post.description }}
                            </p>
                        </div>
                        <!-- <div class="relative mt-8 flex items-center gap-x-4">
                            <img
                                :src="post.author.imageUrl"
                                alt=""
                                class="h-10 w-10 rounded-full bg-gray-100"
                            />
                            <div class="text-sm leading-6">
                                <p class="font-semibold text-gray-900">
                                    <a :href="post.author.href">
                                        <span class="absolute inset-0" />
                                        {{ post.author.name }}
                                    </a>
                                </p>
                                <p class="text-gray-600">
                                    {{ post.author.role }}
                                </p>
                            </div>
                        </div> -->
                    </div>
                </article>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const posts = [
    {
        id: 1,
        title: 'Manage your domain portfolio',
        href: '/blog/manage-your-domain-portfolio',
        description:
            "With Domain Pool you can register new domains, transfer existing domains, manage nameservers, manage DNS, setup and manage local registration contacts and consolidate your invoices.",
        imageUrl: '/images/pool-2b.jpg',
        imageAlt: 'Image 1',
        imageTitle: 'Image title 1',
        // date: 'Mar 16, 2020',
        // datetime: '2020-03-16',
        category: { title: 'Marketing', href: '#' },
        author: {
            name: 'Michael Foster',
            role: 'Co-Founder / CTO',
            href: '#',
            imageUrl:
                'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    },
    {
        id: 2,
        title: 'Simplify your business',
        href: '#',
        description:
            'Efficiently manage all your domains in one place. Whether you\'re a small business owner or a seasoned domain investor, Domain Pool offers a professional solution tailored to your needs.',
        imageUrl: '/images/pool-1.jpg',
        imageAlt: 'Image 2',
        imageTitle: 'Image title 2',
        date: 'Mar 16, 2020',
        datetime: '2020-03-16',
        category: { title: 'Marketing', href: '#' },
        author: {
            name: 'Michael Foster',
            role: 'Co-Founder / CTO',
            href: '#',
            imageUrl:
                'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    },
    {
        id: 3,
        title: 'Organize your clients',
        href: '#',
        description:
            'Easy organisation management. Manage multiple clients with separate billing and invoices from 1 login. Buy new domains for specific clients. Transfer in domains to consolidate registrars.',
        imageUrl: '/images/pool-3.jpg',
        imageAlt: 'Image 3',
        imageTitle: 'Image title 3',
        date: 'Mar 16, 2020',
        datetime: '2020-03-16',
        category: { title: 'Marketing', href: '#' },
        author: {
            name: 'Michael Foster',
            role: 'Co-Founder / CTO',
            href: '#',
            imageUrl:
                'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    },
    // More posts...
]
</script>
